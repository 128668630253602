import { useSettings } from '../contexts/SettingsContext';
import {
  FaWhatsapp, FaFacebookF, FaInstagram, FaLinkedinIn, FaXTwitter, FaEnvelope, FaPhone
} from 'react-icons/fa6';
import { FaLocationDot } from 'react-icons/fa6';

export default function Footer({ language }) {
  const currentYear = new Date().getFullYear();
  const { loading, getSettingValue } = useSettings();
  const logo = getSettingValue('site_logo');
  const socialMedia = getSettingValue('social_media') || [];

  const getSocialIcon = (key) => {
    const iconClasses = "w-4 h-4";
    switch (key.toLowerCase()) {
      case 'linkedin': return <FaLinkedinIn className={iconClasses} />;
      case 'twitter': case 'x': return <FaXTwitter className={iconClasses} />;
      case 'instagram': return <FaInstagram className={iconClasses} />;
      case 'facebook': return <FaFacebookF className={iconClasses} />;
      case 'whatsapp': return <FaWhatsapp className={iconClasses} />;
      default: return null;
    }
  };

  const getSocialLabel = (key) => {
    switch (key.toLowerCase()) {
      case 'linkedin':
        return language === 'ar' ? 'لينكد إن' : 'LinkedIn';
      case 'twitter':
      case 'x':
        return language === 'ar' ? 'تويتر' : 'Twitter';
      case 'instagram':
        return language === 'ar' ? 'انستغرام' : 'Instagram';
      case 'facebook':
        return language === 'ar' ? 'فيسبوك' : 'Facebook';
      case 'whatsapp':
        return language === 'ar' ? 'واتساب' : 'WhatsApp';
      default:
        return key;
    }
  };

  const getUniqueSocialMedia = (socialMedia = []) => {
    const seen = new Set();
    return socialMedia.filter(social => {
      const key = social.key.toLowerCase();
      if (seen.has(key)) return false;
      seen.add(key);
      try { new URL(social.value); return true; } catch { return false; }
    });
  };

  return (
    <footer className="relative bg-gradient-to-b from-white to-gray-50 mt-auto border-t border-gray-100">
      {/* Top subtle border */}
      <div className="absolute top-0 left-0 right-0 h-0.5 bg-gradient-to-r from-transparent via-[#BE092B]/20 to-transparent"></div>

      <div className="container mx-auto px-4 pt-6 pb-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 justify-items-center">
          {/* Company Logo - Column 1 */}
          <div className="w-full flex flex-col items-center sm:items-start">
            {loading ? (
              <div className="h-16 w-28 bg-gray-100 animate-pulse rounded"></div>
            ) : logo ? (
              <img src={logo} alt="Stark Real Estate" className="h-16 w-auto mb-2 hover:opacity-90 transition-opacity" />
            ) : null}

            <p className={`text-[10px] text-gray-500 ${language === 'ar' ? 'font-arabic text-center sm:text-right' : 'text-center sm:text-left'}`}>
              {language === 'ar'
                ? `© ${currentYear} ستارك العقارية`
                : `© ${currentYear} Stark Real Estate`}
            </p>
          </div>

          {/* About Us - Column 2 */}
          <div className="w-full">
            <h3 className={`text-xs font-semibold mb-2 text-gray-800 relative pb-1 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-8 before:h-0.5 before:bg-[#BE092B] ${language === 'ar' ? 'font-arabic text-right before:right-0 before:left-auto' : 'text-left'}`}>
              {language === 'ar' ? 'عن الشركة' : 'About Us'}
            </h3>
            <p className={`text-xs text-gray-700 leading-relaxed mb-2 ${language === 'ar' ? 'font-arabic text-right' : 'text-left'}`}>
              {language === 'ar'
                ? 'نقدم خدمات عقارية استثنائية في جميع أنحاء المملكة. نتميز بالاحترافية والالتزام بالجودة في كل ما نقدمه.'
                : 'We provide exceptional real estate services throughout the Kingdom. We are distinguished by professionalism and commitment to quality.'}
            </p>
          </div>

          {/* Quick Links - Column 3 */}
          <div className="w-full">
            <h3 className={`text-xs font-semibold mb-2 text-gray-800 relative pb-1 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-8 before:h-0.5 before:bg-[#BE092B] ${language === 'ar' ? 'font-arabic text-right before:right-0 before:left-auto' : 'text-left'}`}>
              {language === 'ar' ? 'روابط سريعة' : 'Quick Links'}
            </h3>
            <div className={`grid grid-cols-1 gap-y-1 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
              <a href="/about" className={`text-xs text-gray-600 hover:text-[#BE092B] hover:translate-x-1 transition-all ${language === 'ar' ? 'font-arabic hover:-translate-x-1' : ''}`}>
                {language === 'ar' ? 'من نحن' : 'About Us'}
              </a>
              <a href="/terms" className={`text-xs text-gray-600 hover:text-[#BE092B] hover:translate-x-1 transition-all ${language === 'ar' ? 'font-arabic hover:-translate-x-1' : ''}`}>
                {language === 'ar' ? 'الشروط والأحكام' : 'Terms & Conditions'}
              </a>
              <a href="/privacy" className={`text-xs text-gray-600 hover:text-[#BE092B] hover:translate-x-1 transition-all ${language === 'ar' ? 'font-arabic hover:-translate-x-1' : ''}`}>
                {language === 'ar' ? 'سياسة الخصوصية' : 'Privacy Policy'}
              </a>
              <a href="/customer-service" className={`text-xs text-gray-600 hover:text-[#BE092B] hover:translate-x-1 transition-all ${language === 'ar' ? 'font-arabic hover:-translate-x-1' : ''}`}>
                {language === 'ar' ? 'اتصل بنا' : 'Contact Us'}
              </a>
            </div>
          </div>

          {/* Connect With Us - Column 4 */}
          <div className="w-full">
            <h3 className={`text-xs font-semibold mb-2 text-gray-800 relative pb-1 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-8 before:h-0.5 before:bg-[#BE092B] ${language === 'ar' ? 'font-arabic text-right before:right-0 before:left-auto' : 'text-left'}`}>
              {language === 'ar' ? 'تواصل معنا' : 'Connect With Us'}
            </h3>

            <div className="flex flex-wrap items-center gap-2 mb-2">
              {loading ? (
                <div className="flex gap-2">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="w-7 h-7 bg-gray-100 animate-pulse rounded-full"></div>
                  ))}
                </div>
              ) : (
                getUniqueSocialMedia(socialMedia).map((social) => {
                  const icon = getSocialIcon(social.key);
                  if (!icon) return null;

                  return (
                    <a
                      key={social.id}
                      href={social.value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center w-7 h-7 rounded-full bg-white shadow-sm hover:shadow-md text-gray-600 hover:text-white hover:bg-[#BE092B] transform hover:scale-105 transition-all duration-200"
                      aria-label={social.key}
                      title={social.key}
                    >
                      {icon}
                    </a>
                  );
                })
              )}
            </div>

            <div className={`${language === 'ar' ? 'text-right' : 'text-left'}`}>
              <div className="flex items-center mb-1">
                <FaLocationDot className="text-[#BE092B] w-3 h-3 flex-shrink-0 mr-1" />
                <p className={`text-xs text-gray-700 ${language === 'ar' ? 'font-arabic' : ''}`}>
                  {language === 'ar' ? 'الرياض، النبراس 12353' : 'Riyadh, Al-Nibras 12353'}
                </p>
              </div>
              <p className={`text-[10px] text-gray-500 ${language === 'ar' ? 'font-arabic' : ''}`}>
                {language === 'ar' ? 'س.ت: 1009136771 | ض.ق.م: 312660190300003' : 'CR: 1009136771 | VAT: 312660190300003'}
              </p>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="w-full h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-2"></div>

        {/* Rights */}
        <div className="text-center">
          <p className={`text-[10px] text-gray-500 ${language === 'ar' ? 'font-arabic' : ''}`}>
            {language === 'ar'
              ? 'جميع الحقوق محفوظة'
              : 'All rights reserved'}
          </p>
        </div>
      </div>
    </footer>
  );
}
